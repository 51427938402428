import { ComponentFactoryResolver, NgModule, ComponentFactory } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { CalculatorApi } from '../../shared/services/calculator-api/calculator-api.service';
import { CalculatorHeaderModule } from '../../features/calculators/calculator-header/calculator-header.module';
import { ClimateCareCalculatorModule } from '../../features/calculators/climatecare-calculator.module';
import { ClimateCareComponent } from './climatecare.component';
import { TabbedContainerModule } from '../../shared/components/tabbed-container/tabbed-container.module';

import { ByPassService } from '../../shared/services/bypass/bypass.service';

@NgModule({
  declarations: [
    ClimateCareComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    TabbedContainerModule,
    CalculatorHeaderModule,
    ClimateCareCalculatorModule
  ],
  providers: [
    CalculatorApi,
    ByPassService
  ],
  entryComponents: [
    ClimateCareComponent
  ]
})
export class ClimateCareModule {
  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  public resolveComponent(): ComponentFactory<ClimateCareComponent> {
    return this.componentFactoryResolver.resolveComponentFactory(ClimateCareComponent);
  }
}
