import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CalculatorApi } from '../../../shared/services/calculator-api/calculator-api.service';
import { Project } from '../../../shared/services/calculator-api/project.interface';
import { PaymentService, PROVIDER_STRIPE } from '../../../shared/services/payments/payment.service';

import { AppConfiguration } from '../../../bootstrap/climatecare/app.configuration';
import { Cart } from '../../../shared/services/cart/cart.interface';
import { CartService } from '../../../shared/services/cart/cart.service';
import { FeatureComponent } from '../feature-component-registry/feature-component.interface';
import { NavigationService } from '../../../shared/services/navigation/navigation.service';
import { TranslateService } from '@ngx-translate/core';


import { ByPassService }  from '../../../shared/services/bypass/bypass.service';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['../../../../assets/css/climatecare/app.css', './customer-details.component.css']
})
export class CustomerDetailsComponent implements OnInit, FeatureComponent {

  data: any;

  cart: Cart;

  detailsForm: FormGroup;

  appConfiguration: AppConfiguration;

  rightCaption = 'Credit Card';

  middleCaption = 'Paypal';

  checkoutCaption = 'Checkout';

  customerDetailsTarget = 'customerDetailsTarget';

  paying = false;

  payingPaypal = false;

  customCartHeaderColor = '';

  private skipFormUpdate = false;

  private project: Project;

  constructor(private injector: Injector) {
    this.appConfiguration = this.injector.get(AppConfiguration);
    this.detailsForm = this.injector.get(FormBuilder).group({
      customerName: [ '', Validators.required ],
      organizationName: [ '' ],
      customerEmail: [ '', [ Validators.required, Validators.email ] ]
    });

    this.injector.get(CartService).getCart().subscribe((cart: Cart) => {
      this.cart = cart;
      this.rightCaption = '';
      this.middleCaption = '';

      const translateService = this.injector.get(TranslateService);

      if (this.cart.totalCost > 0) {
        translateService.get('pay-offsets.credit-card-caption').subscribe((res: string) => {
          this.rightCaption= res;

        });
        translateService.get('pay-offsets.paypal-caption').subscribe((res: string) => {
          this.middleCaption = res;
        });
      } else {
        translateService.get('pay-offsets.checkout-caption').subscribe((res: string) => {
          this.rightCaption = res;
        });
      }
    });

    this.injector.get(CalculatorApi).
      getSelectedProject().
      subscribe((project: Project) => {
        this.project = project;
      });

    this.detailsForm.valueChanges.subscribe(form => {
      if (!this.skipFormUpdate) {
        this.cart.customerName = form.customerName;
        this.cart.organizationName = form.organizationName;
        this.cart.customerEmail = form.customerEmail;
      }
    });

    this.customCartHeaderColor =
      this.appConfiguration.clientConfiguration.clientSettings.customCartHeaderColor;
  }

  ngOnInit(): void {}

  middle(): void {
    this.payingPaypal = true;
  }

  next(): void {
    if (this.cart.totalCost > 0) {
      this.resetDetailsForm();
      this.injector.get(NavigationService).slideActiveTabForward({
        showNavigationMenu: false,
        showCheckout: false,
        showCurrencySelector: false,
        showDefaultHeader: true,
        isStart: false
      });
    } else {
      this.pay();
    }
  }

  back(): void {
    this.resetDetailsForm();
    this.injector.get(NavigationService).slideActiveTabBack({
      showNavigationMenu: false,
      showCheckout: false,
      showCurrencySelector: true,
      showDefaultHeader: true,
      isStart: false
    });
  }

  private resetDetailsForm() {
    this.skipFormUpdate = true;
    this.detailsForm.reset();
    this.skipFormUpdate = false;
  }

  private pay(): void {
    const paymentType = 'offset-purchase';

    this.paying = true;

    const paymentService = this.injector.get(PaymentService);

    paymentService.completePayment({
      provider: PROVIDER_STRIPE,
      cart: this.cart,
      projectId: this.project.id,
      projectName: this.project.title,
      type: paymentType
    }).subscribe((result: any) => {
      this.paying = false;
      this.injector.get(NavigationService).slideActiveTabEnd({
        showNavigationMenu: false,
        showCheckout: false,
        showCurrencySelector: false,
        showDefaultHeader: false,
        isStart: false
      });
    });
  }
}
