import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularStripeService } from '@fireflysemantics/angular-stripe-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AppConfiguration } from '../../../bootstrap/climatecare/app.configuration';
import { Stripe } from '@fireflysemantics/angular-stripe-service/lib/types';


import { ByPassService } from '../../../shared/services/bypass/bypass.service';

export interface PaymentState {
  cardComplete: boolean;
  stripe: Stripe;
  card: any;
};

@Component({
  selector: 'app-flex-stripe-card',
  templateUrl: './flex-stripe-card.component.html',
  styleUrls: ['./flex-stripe-card.component.css']
})
export class FlexStripeCardComponent implements OnInit {

  detailsForm: FormGroup;

  errors: Array<string>;

  card: any;

  private stripe: Stripe;

  @Output()
  private cardCompleted: EventEmitter<PaymentState>;

  constructor(
    private appConfiguration: AppConfiguration,
    private formBuilder: FormBuilder,
    private stripeService: AngularStripeService,
    private byPassService: ByPassService) {

    let stripeKey = this.appConfiguration.clientConfiguration.stripePublishableKey;
   
    if(this.byPassService.isByPass){
      stripeKey = this.appConfiguration.clientConfiguration.stripePublishableKeyTest;
    }

    this.stripeService.setPublishableKey(stripeKey).
      then((stripe: Stripe) => { 
        this.buildCard(stripe);
      });

    this.detailsForm = this.formBuilder.group({
      customerName: [ '', Validators.required ]
    });

    this.cardCompleted = new EventEmitter<PaymentState>();
  }

  ngOnInit(): void {}

  private buildCard(stripe: Stripe) {
    this.stripe = stripe;

    const elements = this.stripe.elements({
      fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
        },
      ],
      locale: this.appConfiguration.clientConfiguration.clientSettings.defaultLanguage
    });

    this.card = elements.create(
      'card',
      {
        hidePostalCode: true
      }
    );

    this.card.mount('#card-element');
    
    this.card.on('change', (event) => {
      this.errors = [];

      if (event.error) {
        this.errors.push(event.error.message);    
      } else {
        this.cardCompleted.emit({
          cardComplete: event.complete,
          stripe: this.stripe,
          card: this.card
        });
      }
    }); 
  }
}
