import { Injectable } from '@angular/core';



@Injectable()

export class ByPassService {

    isByPass = false;
   
    constructor() { }

    set(value: boolean) {
        this.isByPass = value;
    }

}
