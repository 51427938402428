import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { switchMap, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { AppConfiguration } from './app.configuration';
import { CalculatorApi } from '../../shared/services/calculator-api/calculator-api.service';
import { EmissionsService } from '../../shared/services/emissions/emissions.service';
import { PartnerConfig } from '../../shared/services/calculator-api/partner-config.interface';
import { PostMessage, PostMessageEvent } from '../../shared/services/post-message/post-message';
import { PostMessageService } from '../../shared/services/post-message/post-message.service';

import { ByPassService } from '../../shared/services/bypass/bypass.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  appReady = false;

  appAuthorized = false;

  constructor(
    private appConfiguration: AppConfiguration,
    private postMessageService: PostMessageService,
    private calculatorApi: CalculatorApi,
    private emissionsService: EmissionsService,
    private translate: TranslateService,
    private byPassService : ByPassService) {
    this.translate.setDefaultLang('en');
    this.translate.use(this.appConfiguration.clientConfiguration.clientSettings.defaultLanguage);
  }

  ngOnInit() {
   
    if (!window.location.search) {
      this.initPartnerConfig();
    } else {
      const byPassed = new URLSearchParams(window.location.search).get('bypass') === this.appConfiguration.clientConfiguration.clientSettings.bypassCode;
      if (byPassed) {
        this.initPartnerConfig();
        this.byPassService.set(byPassed);
      } else {
        this.unAuthorize();
      }
    }

  }

  private initPartnerConfig() {
    const partnerConfig =
      this.appConfiguration.clientConfiguration.clientSettings.partnerConfig;

    if (partnerConfig.partnerKey) {
      this.resolvePartnerConfig(partnerConfig);
    } else {
      this.initIpc();
    }
  }

  private initIpc() {
    const txOrigins = this.appConfiguration.clientConfiguration.
      clientSettings.txOrigins;
    const rxOrigins = this.appConfiguration.clientConfiguration.
      clientSettings.rxOrigins;

    this.postMessageService.setRxOrigins(rxOrigins);
    this.postMessageService.setTxOrigins(txOrigins);

    this.postMessageService.tx({
      event: PostMessageEvent.PARTNER_CONFIG_REQUESTED
    });

    this.postMessageService.postMessage.subscribe(
      (postMessage: PostMessage) => {
        if (postMessage && postMessage.event === PostMessageEvent.PARTNER_CONFIG_SENT) {
          this.resolvePartnerConfig(postMessage.payload);
        }
      }
    );
  }

  private resolvePartnerConfig(partnerConfig: PartnerConfig) {
    this.calculatorApi.getPartnerConfig(partnerConfig).
      pipe(
        switchMap(
          (data: PartnerConfig | HttpErrorResponse) => {
            if (data instanceof HttpErrorResponse) {
              this.appReady = true;
            } else {
              this.appConfiguration.clientConfiguration.clientSettings.partnerConfig = data;
              return this.calculatorApi.getProjects(true);
            }
          }
        ),
        switchMap(() => {
            return this.emissionsService.getCountries().pipe(
              map((countries: Array<any>) => {
                this.appConfiguration.clientConfiguration.countries.next(countries);
              })
            );
          }
        )
      ).subscribe(() => {
        this.authorize();
      });
  }

  private authorize() {
    this.appReady = true;
    this.appAuthorized = true;
  }

  private unAuthorize(){
    this.appReady = true;
    this.appAuthorized = false;
  }
}
