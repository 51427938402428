// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  client: 'bcg',
  production: false,
  stripePublishableKey: 'pk_test_AKFzXNrTAU4SAL1NTUimhbl3',
  stripePublishableKeyTest : '%stripeTestClientId%',
  paypalClientId: 'ASWolWWmcWqVsTLFo4wdwlURxKGrXJYsWlWXoPzNzkza6apKHo_MkCb65jKoUpWornyGqqGUZTog2g7A',
  paypalClientIdTest: '%paypalTestClientId%',
  siteUrl: 'http://dev-bcg.co2analytics.com/',
  defaultLanguage: 'en',
  minimumBaseCurrencyAmount: 0.75,
  hideIntroFooterCaption: true,
  postPurchaseButtonColor: '#177B57',
  customCartHeaderColor: '#177B57',
  logoImg: {
    name: 'bcg-logo.png',
    height: '76px',
    width: '180px'
  },
  footerLogoClass: 'ncp-rev',
  txOrigins: [
    '*'
  ],
  rxOrigins: [
    '*'
  ],
  partnerConfig: {
    partnerKey: 'f17e3f21-6500-4f2f-bec3-239b4b2d2deb',
    domain: 'climatecare.org'
  },
  apis: {
    domain: 'https://dev-srvc-calculators.co2analytics.com',
    fx: 'https://dev-srvc-ecomm.co2analytics.com/api/v1',
    fin: 'https://dev-srvc-ecomm.co2analytics.com/api/v1',
    version: 'v1.0.0'
  },
  bypassCode: '%bypassCode%',
  componentHierarchy: {
    headerComponents: [
      { templateName: 'calculatorHeaderComponent', componentName: 'CalculatorHeaderComponent' }
    ],
    featureComponents: [
      {
        name: 'intro',
        seqn: '0',
        title: 'Please select a category to begin calculating your carbon emissions',
        theme: {
          fillColor: '#177B57',
          textClass: 'calculator-selector-text-dark'
        },
        component: { templateName: 'introComponent', componentName: 'IntroComponent' }
      },
      {
        name: 'start',
        seqn: '1',
        title: 'Quick offset',
        theme: {
          fillColor: '#2f8462',
          textClass: 'calculator-selector-text-light'
        },
        component: { templateName: 'startComponent', componentName: 'ClimateCareStartComponent' }
      },
      {
        name: 'flights',
        seqn: '2',
        title: 'Flights',
        theme: {
          fillColor: '#428d6d',
          textClass: 'calculator-selector-text-light'
        },
        component: { templateName: 'flightsComponent', componentName: 'ClimateCareFlightsComponent' }
      },
      {
        name: 'transportation',
        seqn: '3',
        title: 'Road Transport',
        theme: {
          fillColor: '#539778',
          textClass: 'calculator-selector-text-light'
        },
        component: { templateName: 'transportationComponent', componentName: 'ClimateCareTransportationComponent' }
      },
      {
        name: 'energy',
        seqn: '4',
        title: 'Energy',
        theme: {
          fillColor: '#63a084',
          textClass: 'calculator-selector-text-light'
        },
        component: { templateName: 'energyComponent', componentName: 'ClimateCareEnergyComponent' }
      },
      {
        name: 'events',
        seqn: '5',
        title: 'Events',
        theme: {
          fillColor: '#73a98f',
          textClass: 'calculator-selector-text-light'
        },
        component: { templateName: 'eventsComponent', componentName: 'ClimateCareEventComponent' }
      },
      {
        name: 'accommodations',
        seqn: '6',
        title: 'Accommodations',
        theme: {
          fillColor: '#83b39b',
          textClass: 'calculator-selector-text-light'
        },
        component: { templateName: 'accommodationsComponent', componentName: 'ClimateCareAccommodationsComponent' }
      },
      {
        name: 'consumption',
        seqn: '7',
        title: 'Food',
        theme: {
          fillColor: '#92bca7',
          textClass: 'calculator-selector-text-light'
        },
        component: { templateName: 'consumptionComponent', componentName: 'ClimateCareConsumptionComponent' }
      },
      {
        name: 'freight',
        seqn: '8',
        title: 'Freight',
        theme: {
          fillColor: '#a1c6b3',
          textClass: 'calculator-selector-text-light'
        },
        component: { templateName: 'freightComponent', componentName: 'ClimateCareFreightComponent' }
      },
      {
        name: 'waste',
        seqn: '9',
        title: 'Waste',
        theme: {
          fillColor: '#b1cfc0',
          textClass: 'calculator-selector-text-light'
        },
        component: { templateName: 'wasteComponent', componentName: 'ClimateCareWasteComponent' }
      }
    ],
    global: [
      {
        component: { defaultFeatureIndex: 0 }
      },
      {
        component: { templateName: 'offsetProjectsComponent', componentName: 'OffsetProjectsComponent' }
      },
      {
        component: { templateName: 'cartComponent', componentName: 'CartComponent' }
      },
      {
        component: { templateName: 'customerDetailsComponent', componentName: 'CustomerDetailsComponent' }
      },
      {
        component: { templateName: 'payOffsetsComponent', componentName: 'PayOffsetsComponent' }
      },
      {
        component: { templateName: 'confirmationComponent', componentName: 'ConfirmationComponent' }
      }
    ],
    footerComponents: [
      { templateName: 'icroaFooterComponent', componentName: 'IcroaFooterComponent' }
    ]
  }
}

// For easier debugging in development mode, you can import the following file
// to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
//
// This import should be commented out in production mode because it will have a negative impact
// on performance if an error is thrown.
//
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
