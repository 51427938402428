import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AppConfiguration } from '../../../bootstrap/climatecare/app.configuration';
import { AngularStripeService } from '@fireflysemantics/angular-stripe-service';
import { Cart } from '../cart/cart.interface';
import { CartItem } from '../../../shared/services/cart/cart-item.interface';

import { ByPassService } from '../../../shared/services/bypass/bypass.service';

export const PROVIDER_STRIPE = 'stripe';
export const PROVIDER_PAYPAL = 'paypal';

export interface OffsetPurchase {
  provider?: string;
  cart?: Cart;
  projectId?: number;
  projectName?: string;
  type?: string;
  data?: any;
  mode?: string;
}

@Injectable()
export class PaymentService {

  constructor(
    private appConfiguration: AppConfiguration,
    private httpClient: HttpClient,
    private stripeService: AngularStripeService, 
    private byPassService: ByPassService) { }

  createProductPayment(
    productId: number,
    paymentProvider: string,
    amount: number,
    currency: string,
    email: string,
    description: string,
    organizationId: number): Observable<any | Array<string>> {
    return this.httpClient.post<any>(
      this.appConfiguration.clientConfiguration.endpoints['initiatePayment'],
      {
        productId,
        paymentProvider,
        amount,
        currency,
        email,
        description,
        organizationId
      },
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((data: object) => {
        return data['data'];
      }),
      map((result: any) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }

  updateProductPayment(
    productChargeId: number,
    productChargeType: string,
    paymentProvider: string): Observable<any | Array<string>> {
    return this.httpClient.put<any>(
      this.appConfiguration.clientConfiguration.endpoints['updatePayment'],
      {
        productChargeId,
        productChargeType,
        paymentProvider
      },
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((data: object) => {
        return data['data'];
      }),
      map((result: any) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }

  initPayment(
    provider: string,
    providerAccount: string,
    cart: Cart,
    projectId: number,
    projectName: string,
    card: any,
    stripe: any): Observable<any | Array<string>> {

    let metadata =  {
      "tCO2e": cart.totalTonnes,
      "company": cart.organizationName,
      "contact": cart.customerName,
      "email": cart.customerEmail
    };

    let voucher = null;
    let vat = null;

    cart.items.forEach((item: CartItem) => {
      if (item.voucherCode) {
        voucher = item.voucherCode;
      } 
      if (item.vat ){
        vat = item.vat;
      }
    });
    
    if (voucher) {
      metadata =  {...metadata, ...voucher};
    }

    if(vat) {
      metadata =  {...metadata, ...vat};
    }

    return this.httpClient.post<any>(
      this.appConfiguration.clientConfiguration.endpoints['initPayment'] +
      '?providerOwner=' +
      providerAccount,
      {
        provider,
        amount: parseFloat(cart.totalCost.toFixed(2)),
        currency: cart.currency.iso2,
        email: cart.customerEmail,
        description: 'Carbon Offset for ' + cart.organizationName  + ' ('  + cart.customerEmail + ')',
        metadata: metadata,
        mode: this.byPassService.isByPass? 'SANDBOX' : 'LIVE'
      },
      {
        observe: 'body',
        responseType: 'json',
        withCredentials: false
      }
    ).pipe(
      switchMap((intent: any) => {
        return stripe.handleCardPayment(
          intent.client_secret, card
        );
      }),
      switchMap((data: any) => {
        if (data.paymentIntent) {
          const paymentType = 'offset-purchase';

          return this.completePayment({
            provider,
            cart,
            projectId,
            projectName,
            data,
            type: paymentType,
            mode: this.byPassService.isByPass? 'SANDBOX' : 'LIVE'
          });
        } else {
          return data;
        }
      }),
      catchError(this.handleError)
    );
  }

  completePayment(offsetPurchase: OffsetPurchase): Observable<any | Array<string>> {
    return this.httpClient.put<any>(
      this.appConfiguration.clientConfiguration.endpoints['completePayment'],
      offsetPurchase,
      {
        observe: 'body',
        responseType: 'json',
        withCredentials: false
      }
    ).pipe(
      map((result: any) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse): Observable<Array<string>> {
    return of((error.error.errors));
  }
}

